// Here you can add other styles
.otp-section {
  .form-control {
    width: 36px !important;
    height: 36px;
    padding: 0;
    margin: 0 5px;
  }
}
.callout-warning {
  border-left-color: #f79d0f;
}
.callout-danger {
  border-left-color: #e04848;
}
.callout-primary {
  border-left-color: #2719b4;
}
.callout-info {
  border-left-color: #2c87d8;
}
.user-dp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 5px;
  border: 1px solid #20a8d8;
  background: #20a8d8;
  color: #fff;
}
.user-dp img {
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.app-header li.dropdown.nav-item a.nav-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-right: 10px;
}

.user-title {
  font-size: 15px;
  font-weight: 600;
  color: #408fc3;
  line-height: 20px;
}

.Select.header-select {
  width: 250px !important;
}

.Select.header-select .Select-value {
  text-align: left;
}
.Select.header-select .Select-menu-outer {
  text-align: left;
}

.Select.header-select .Select-control {
  text-align: left;
}

.loader-shade:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 99;
  opacity: 0.5;
}

.sk-fading-circle {
  position: absolute !important;
  left: 0;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 99;
}

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

.search-btn-provider {
  margin-bottom: 10px;
  //margin-left: -12px;
  position: relative;
}

// .spinner-btn-primary {
//   position: relative;
// }

// .spinner-btn-primary .spinner-border {
//   // display: inline-block;
//   // width: 2rem;
//   // height: 2rem;
//   // vertical-align: text-bottom;
//   // border: 0.25em solid currentColor;
//   // border-right-color: transparent;
//   // border-radius: 50%;
//   // -webkit-animation: spinner-border 0.75s linear infinite;
//   // animation: spinner-border 0.75s linear infinite;
//   margin: 0 auto;
//   text-align: center;
//   position: absolute;
//   bottom: -27px;
//   right: 10px;
// }

span.ladda-label {
  display: inline-block;
  /* align-items: center; */
  margin-right: 15px;
}

button.ladda-button.btn.btn-primary.btn-ladda {
  display: flex;
  align-items: center;
}

span.ladda-spinner {
  display: inline-block;
}

body.header-fixed.sidebar-lg-show.sidebar-fixed.aside-menu-fixed.aside-menu-off-canvas {
  position: relative;
}

.form-control {
  padding: 7px 35px 7px 10px;
}

.remove-show {
  z-index: 111;
  position: absolute;
  right: 50px;
  top: -13px;
}

.form-check-input.check-box-login {
  width: 20px;
  height: 20px;
}
.admin-checkbox {
  margin: auto;
}

.full-btn-selection {
  .Select-control {
    background-color: #20a8d8;
    .Select-value {
      .Select-value-label {
        color: #fff !important;
      }
    }
    .Select-clear {
      color: #fff !important;
    }
    .Select-arrow {
      border-color: #fff transparent transparent !important;
    }
  }
}
.border-selection {
  .Select-control {
    border: 2px solid #20a8d8 !important;
  }
}

/* singledropdown*/
.dropdpwn-relative {
  position: relative;
}
.singledropdown {
  position: absolute;
  left: 0;
  top: 100%;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: solid 1px #e4e7ea;
  border-radius: 2px;
  padding: 6px 0;
  display: none;
  max-height: 170px;
  overflow: auto;
  &.show {
    display: block;
  }
  li {
    list-style-type: none;
    padding: 5px 15px;
    cursor: pointer;
    + li {
      margin-top: 1px;
    }
    &:hover,
    &.selected {
      background-color: #eee;
    }
  }
  .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
    margin-right: 8px;
  }
  .spinner-text {
    color: #73818f;
  }
}

.dropdpwn-relative td {
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  width: 100%;
  display: block;
  /* border-top: 0; */
}
.ladda-button {
  position: relative;
  .ladda-label {
    margin-right: 0;
  }
  .ladda-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.modal-patient {
  .callout {
    strong {
      font-weight: 600;
    }
    small {
      font-weight: 600;
    }
  }
  .modal-body {
    padding: 1rem 1.5rem;
    .card-body {
      padding: 1.25rem 0 0;
    }
  }

  .modal-footer {
    justify-content: space-between;
    .btn-danger {
      background-color: #dc1c1e;
    }
  }
}
.anchor-table tr {
  cursor: pointer;
}
// Visit List
.modal-patient {
  .badge {
    &.badge-primary {
      padding: 5px 10px;
    }
  }
}
.visit-list {
  &.modal-body {
    padding: 0;
    margin: 0;
  }
  .sk-fading-circle {
    position: relative !important;
    margin-top: 10px;
  }
  .visit-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: solid 1px #d8dbe0;
    padding: 8px 25px;
    &:last-child {
      border: none;
    }
  }
  .visit-image {
    width: 35px;
    height: 35px;
    border: solid 1px #dde1ee;
    padding: 5px;
    border-radius: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .visit-content {
    width: calc(100% - 35px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 10px;
    align-items: center;
    .visit-left {
      width: calc(100% - 140px);
      color: #1e1c1c;
      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-bottom: 4px;
      }
    }
    .visit-date {
      font-size: 14px;
    }
    .visit-name {
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0.6px;
    }
    .btn-secondary {
      background-color: #ebedef;
      border-color: #ebedef;
      color: #1e1c1c;
      margin-right: 8px;
    }
    .btn-danger {
      background-color: #e55453;
      border-color: #e55453;
      color: #ffffff;
    }
    .btn {
      padding: 5px 15px;
    }
  }
}

.visit-image.bk-color {
  background-color: #20a8d8;
}

.btn-import {
  background-color: #637184;
  padding: 5px 10px;
  color: #fff;
  float: right;
}

.import-modal {
  .form-control-file {
    display: none;
  }
}

.errors-list {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  padding-right: 50px;
  position: relative;
  border-bottom: solid 1px #e8e8e8;
  &:last-child {
    border: none;
  }
  span {
    font-size: 18px;
    font-weight: 600;
    color: #646464;
    margin-right: 20px;
  }
  p {
    margin-bottom: 0;
  }
  .warning-img {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  .warning-header-img {
    position: absolute;
    /* top: 15px; */
    right: 0;
    margin-top: -44px;
  }
}
.browser-check {
  .app {
    margin-top: 30px;
  }
}
.Select-option {
  color: black;
}
.page-item.active .page-link {
  z-index: 0;
}
#nodata-label {
  width: 100%;
  margin: 10px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  color: darkgrey;
  height: 100%;
  line-height: 100%;
}
.chrome-msg {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  background: #20a8d8;
  padding: 4px 0;
  text-align: center;
  height: 30px;
  z-index: 999;
  span {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
  }
  .fa {
    position: absolute;
    right: 10px;
    top: 6px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
  }
}
.clipboard-toast {
  font-size: 15px;
  font-weight: 600;
}
.org-ladda-demo {
  color: #4f4f4f;
  background-color: #dbedff;
  border: 1px solid rgba(4, 66, 137, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 400;
}
.org-ladda-live {
  color: #4f4f4f;
  background-color: #dcffe4;
  border: 1px solid rgba(4, 66, 137, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 400;
}
.org-checkbox-size {
  width: 1.5rem;
  height: 1.5rem;
}
.org-checkbox-label {
  margin-top: 0.4rem;
  margin-left: 1rem;
  font-weight: 500;
  color: #4f4f4f;
}
.table-overflow-massinvite {
  overflow-x: auto;
  display: block;
  table-layout: auto;
}
