// If you want to override variables do it here
@import "variables";

// Import styles with default layout.
// If you are going to use dark layout please comment next line
@import "~@coreui/coreui-pro/scss/coreui.scss";

// Import styles with dark layout
// If you want to use dark layout uncomment next line
//@import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui-pro/scss/_dropdown-menu-right.scss";

// If you want to add something do it here
@import "custom";

@import "organizationList";

// ie fixes
@import "ie-fix";

// temp fixes
@import "fixes";

// Spinkit
$spinkit-spinner-color: $body-color;
@import "~spinkit/scss/spinkit.scss";

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
