.image-select-div {
  width: 100px;
  height: 100px;
  background-color: #fafafa;
  border-radius: 10px;
  border: 1px dashed #ced4da;
  overflow: hidden;
  background: transparent url("../assets/img/brand/cloud.svg") center no-repeat;
  .remove-image-btn {
    position: absolute !important;
    top: 5px;
    right: 5px;
    z-index: 3;
  }
  img {
    object-fit: contain;
    background-color: #fafafa;
    background-size: 100% 100%;
  }
  input {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  ::-webkit-file-upload-button {
    cursor: pointer;
  }
}
